export default {
    methods: {
        makeToast(error, title, variant) {
            this.$bvToast.toast(this.exibirMensagemErro(error), {
                title: title,
                noAutoHide: true,
                appendToast: true,
                variant: variant,
                toaster: "b-toaster-top-center",
            });
        },
        exibirMensagemErro(error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.message) {
                    return error.response.data.message
                }
                return error.response.data
            } else if (typeof error === 'string') {
                return error
            } else if (error && error.message) {
                return error.message
            }
            return error
        }
    }
}