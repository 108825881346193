import Vue from "vue";
import { app } from "@/main.js";

export function updateToken(redirectUri, callback) {
    if (!Vue.$keycloak.authenticated) {
        // The page is protected and the user is not authenticated. Force a login.
        Vue.$keycloak.login({ redirectUri });
    } else {
        try {
            Vue.$keycloak
                .updateToken(60)
                .then(function () {
                    if(app.$store.getters.getUsuario.cpf == undefined) {
                        setUsuarioKeycloak()
                    }
                    callback();
                })
                .catch(function () {
                    Vue.$keycloak.login({ redirectUri });
                });
        } catch (err) {
            console.log("Failed to refresh token");
        }
    }
}

export function logout() {
    Vue.$keycloak.logout({ redirectUri: window.location.origin });
    app.$store.commit("setUsuario", {
        cpf: "",
        telefone: "",
        email: "",
        endereco: { cep: "" },
    });
    window.open("https://sso.acesso.gov.br/logout", "_blank");
}

export function setUsuarioKeycloak() {
    if ((Vue.$keycloak.token && Vue.$keycloak.idToken) != "") {
        app.$store.commit("setUsuario", {
            cpf: Vue.$keycloak.tokenParsed.preferred_username,
            telefone: Vue.$keycloak.tokenParsed.phone_number,
            email: Vue.$keycloak.tokenParsed.email,
            endereco: { cep: "" },
        });
    }
}
