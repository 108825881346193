<template>
  <div class="home">
    <b-container>
      <b-row>
        <b-col>
          <h4 id="title-content"
              class="mt-3"
              tabindex="-1">
            <span>
              Solicite o agendamento da emissão da sua Carteira de Identidade
              Nacional (CIN)
            </span>
          </h4>

          <div class="mt-5">
            <div class="mb-4">
              <div class="mb-2"><span><b>O que é?</b></span><br></div>
              <div>
                <p>&emsp;&emsp;Em substituição ao antigo RG, a Carteira de
                  Identidade Nacional (CIN) contém o novo padrão adotado pelos
                  órgãos estaduais de identificação, incluindo novos elementos
                  de segurança, a exemplo de QR Code e uma zona de leitura
                  automatizada. Para saber mais sobre a CIN,
                  <router-link to="/informacoes">
                    <span class="text-danger">Clique Aqui</span>
                  </router-link>.
                </p>
              </div>
            </div>
            <div class="mb-4">
              <div class="mb-2"><span><b>Como agendar?</b></span></div>
              <div>
                <p>
                <ul>
                  <li><b>Online:</b> através deste site.</li>
                  <li><b>Presencial:</b> na <b>Casa da Cidadania de Jaguaribe</b>,
                    que atende preferencialmente idosos e PCDs, deverá
                    ser feito presencialmente ou pelo número (83) 3218-4473,
                    ou no <b>Espaço Cultural</b>, através do Programa Cidadão,
                    sendo o atendimento realizado por ordem de chegada.</li>
                </ul>
                </p>
              </div>
            </div>
            <div class="mb-4">
              <div class="mb-2"><span><b>Documentos obrigatórios para o atendimento</b></span></div>
              <div>
                <p>
                <ul>
                  <li>Certidão original ou autenticada de Nascimento ou Casamento</li>
                  <li>Comprovante de Residência</li>
                  <li>CPF (o qual deve estar regularizado junto à Receita
                    Federal, estando os dados em conformidade com a certidão apresentada.
                    <a href="https://servicos.receita.fazenda.gov.br/servicos/cpf/consultasituacao/consultapublica.asp"
                       target="_blank">
                      <span class="text-danger">Clique Aqui</span></a> para verificar sua situação).
                  </li>
                  <li>RG (caso possua)</li>
                </ul>
                </p>
              </div>
            </div>
          </div>

          <div class="servico mt-4 mb-2">
            <div style="width: 235px; margin: 0 auto;">
              <b-button class="botao_quero_agendar px-5"
                        @click.prevent="$router.push('/via1')"
                        size="lg">Quero agendar
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col sm="1"> </b-col>
        <!-- Consultar DAR -->
        <b-col sm="4">
          <b-card class="mt-5 p-2">
            <b-overlay :show="showOverlay"
                       variant="transparent"
                       opacity="0.9"
                       blur="2px"
                       rounded="sm">
              <b-row>
                <b-col>
                  <h5>
                  Está solicitando a segunda via e já pagou o Documento de Arrecadação de Receitas (DAR)?
                  </h5>
                </b-col>
              </b-row>
              <b-row class="mt-2"
                     v-if="!Object.keys(respostaPagamento).length">
                <b-col>
                  <b-form @submit.prevent="$router.push({ path: '/dar/listar' })"
                          id="form">
                    <b-button class="mt-2"
                              variant="primary"
                              type="submit">
                      Consulte aqui
                    </b-button>
                  </b-form>
                </b-col>
              </b-row>
              <b-row class="mt-2"
                     v-else>
                <b-col>
                  <b-row class="mt-4">
                    <b-col>
                      <span style="white-space: nowrap">
                        <b>Nosso número</b>
                      </span>
                    </b-col>
                    <b-col>
                      <span>{{ respostaPagamento.nrNossoNumero }}</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <span><b>Status</b></span>
                    </b-col>
                    <b-col>
                      <span>{{ respostaPagamento.stLancamento }}</span>
                    </b-col>
                  </b-row>
                  <b-row class="mt-3"
                         v-if="respostaPagamento.stLancamento === 'QUITADO'">
                    <b-col>
                      <b-button variant="success"
                                @click="proximoPasso()">
                        Ir para o agendamento
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row class="mt-3"
                         v-if="respostaPagamento.stLancamento === 'EM ABERTO'">
                    <b-col>
                      <span>
                        <b>OBS.:</b> A compensação bancária do mesmo pode levar
                        até 72 horas.
                      </span>
                    </b-col>
                  </b-row>
                  <b-row class="mt-3"
                         v-if="respostaPagamento.stLancamento === 'UTILIZADO'">
                    <b-col>
                      <span>
                        Esse nosso número já foi utilizado para fazer um
                        agendamento.
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import { somenteNumeros } from "@/global.js";
import axios from "axios";
import environment from "@/environment";
import ToastMixin from "@/mixins/toastMixin";

Vue.prototype.$func = somenteNumeros;

export default {
  name: "HomeItem",
  mixins: [ToastMixin],
  data: function () {
    return {
      showOverlay: false,
      nossoNumero: "",
      cpf: "",
      respostaPagamento: "",
    };
  },
  methods: {
    consultarPagamentoDAR() {
      this.showOverlay = true;
      axios
        .get(
          `${environment.BASE_URL}/dar/cpf/${this.cpf}/dar/${this.nossoNumero}` // verificar
        )
        .then((res) => {
          if (res.data.status === "FAIL") {
            this.makeToast(res.data.message, "Erro", "danger");
          } else {
            this.respostaPagamento = res.data.result;
          }
          this.showOverlay = false;
        })
        .catch((error) => {
          this.makeToast(error, "Erro", "danger");
          this.showOverlay = false;
        });
    },
    proximoPasso() {
      this.$store.commit("setVia", {
        n: 2,
        comAlteracao: null,
        nossoNumero: this.nossoNumero,
      });
      this.$store.commit("setUsuario", {
        cpf: this.cpf,
        endereco: { cep: "" },
      });
      this.$store.commit("passoDadosAgendamento");
      this.$router.push("/agendamento");
    },
  },
};
</script>

<style>
.servico a {
  text-decoration-line: underline;
}

.botao_quero_agendar:hover {
  color: #fff;
  background-color: #36b37e;
  border-color: #36b37e;
}

.botao_quero_agendar {
  color: #fff;
  background-color: #39a377;
  border-color: #37a175;
}
</style>
