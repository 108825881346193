<template>
  <div>
    <div id="relatorio">
      <ReportHeader />

      <b-container
        class="container-report"
        v-if="this.dadosDar.nrNossoNumero != undefined"
      >
        <b-row class="text-center">
          <b-col><h3 class="font-weight-bold">Segurança Pública</h3></b-col>
        </b-row>
        <b-row class="text-center mb-5">
          <b-col><h4>Documento de Arrecadação de Receitas (DAR)</h4></b-col>
        </b-row>

        <b-row>
          <b-col md="4">
            <b>Instruções</b>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            Pagar nas agências do Banco do Brasil ou correspondentes bancários.
          </b-col>
        </b-row>

        <b-row class="mb-5">
          <b-col md="12"> Não receber após vencimento. </b-col>
        </b-row>

        <b-row class="mb-2" v-for="[chave, valor] in report" :key="chave">
          <b-col md="4">
            <b>{{ chave }}</b>
          </b-col>
          <b-col md="8">
            {{ valor }}
          </b-col>
        </b-row>
      </b-container>
      <ReportFooter />
    </div>
    <GenerateReport />
  </div>
</template>

<script>
import ReportHeader from "@/components/utils/report/template/Header.vue";
import GenerateReport from "@/components/utils/report/template/GenerateReport.vue";
import ReportFooter from "@/components/utils/report/template/Footer.vue";
export default {
  name: "DarReport",
  components: { ReportHeader, GenerateReport, ReportFooter },
  data: function () {
    return {
      dadosDar: {},
      report: null,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.dadosDar = this.$store.getters.getDadosDar;
    if (this.dadosDar.nrNossoNumero != undefined) {
      this.report = new Map()
        .set("Cedente: ", "Polícia Civil do Estado da Paraíba")
        .set("Data de emissão: ", this.dadosDar.dtEmissao)
        .set("Data de vencimento: ", this.dadosDar.vencimento)
        .set("Nosso número: ", this.dadosDar.nrNossoNumero)
        .set("Código de barras: ", this.dadosDar.nrCodBarras)
        .set("Valor: ", "R$ " + this.dadosDar.valor);
    } else {
      this.$router.push("/");
    }
  },
};
</script>
