import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        usuario: {},
        via: {
            n: null,
            comAlteracao: null,
            maisDe60: null,
            nossoNumero: null,
        },
        passo: 1,
        cadastrarNoSaa: false,
        cadastrarNaReceita: false,
        dadosAgendamento: { cidadao: {} },
        dadosDar: {}
    },
    getters: {
        getUsuario(state) {
            return state.usuario
        },
        getVia(state) {
            return state.via
        },
        getPasso(state) {
            return state.passo
        },
        getCadastrarNoSaa(state) {
            return state.cadastrarNoSaa
        },
        getCadastrarNaReceita(state) {
            return state.cadastrarNaReceita
        },
        getDadosAgendamento(state) {
            return state.dadosAgendamento
        },
        getDadosDar(state) {
            return state.dadosDar
        }
    },
    mutations: {
        // Usuario
        setUsuario(state, payload) {
            state.usuario = payload
        },
        reiniciarUsuario(state) {
            let auxCPF = ''
            if (Object.keys(state.usuario).length
                && Object.keys(state.usuario.cpf).length) {
                auxCPF = state.usuario.cpf
            }
            state.usuario = {
                cpf: auxCPF,
                telefone: '',
                email: '',
                endereco: { cep: '' },
            }
            state.dadosAgendamento = { cidadao: {} }
            state.dadosDar = {};
        },
        // via
        setVia(state, payload) {
            state.via.n = payload.n
            state.via.comAlteracao = payload.comAlteracao
            state.via.maisDe60 = payload.maisDe60
            state.via.nossoNumero = payload.nossoNumero
        },
        viaComAlteracao(state) {
            state.via.comAlteracao = true
        },
        viaSemAlteracao(state) {
            state.via.comAlteracao = false
        },
        reverterAlteracaoVia(state) {
            state.via.comAlteracao = null
        },
        // passo
        incrementarPasso(state) {
            state.passo += 1
        },
        voltarPasso(state) {
            state.passo -= 1
        },
        passoViaDocumento(state) {
            state.passo = 1
        },
        passoDar(state) {
            state.passo = 2
        },
        passoDadosPessoais(state) {
            state.passo = 3
        },
        passoDadosAgendamento(state) {
            state.passo = 4
        },
        // cadastrarNoSaa
        setCadastrarNoSaa(state, payload) {
            state.cadastrarNoSaa = payload
        },
        // cadastrarNaReceita
        setCadastrarNaReceita(state, payload) {
            state.cadastrarNaReceita = payload
        },
        // dadosReport
        setDadosAgendamento(state, payload) {
            state.dadosAgendamento = payload
        },
        setDadosDar(state, payload) {
            state.dadosDar = payload
        }
    }
})
