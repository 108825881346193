<template>
  <div id="app">
    <MyHeader />
    <MyContent />
    <MyFooter />
  </div>
</template>

<script>
import MyHeader from "@/components/template/MyHeader";
import MyContent from "@/components/template/MyContent";
import MyFooter from "@/components/template/MyFooter";
export default {
  name: "DefaultItem",
  components: { MyHeader, MyContent, MyFooter },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  display: grid;
  grid-template-rows: 95px 1fr 60px;
  grid-template-areas:
    "header"
    "content"
    "footer";
}
</style>