import Vue from 'vue'
import App from './App.vue'

import './config/bootstrap'
import './config/axios'

import store from './config/store'
import router from './config/router'
import VueMask from 'v-mask';
import global from '@/global'

import { updateToken } from "@/middlewares/updateToken";

Vue.config.productionTip = false

Vue.use(VueMask);

Vue.prototype.$tempo = global.tempo
Vue.prototype.$utils = global.utils

Vue.filter('cpf', value => {
  let cpf = value
  if (cpf !== undefined) {
    cpf = cpf.replace(/\D/g, "")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  }
  return cpf
})

import authentication from './config/keycloak'
Vue.use(authentication)

let app = null

Vue.$keycloak
  .init({ onLoad: "check-sso", checkLoginIframe: false })
  .then(() => {
    app = new Vue({
      store,
      router,
      render: h => h(App),
    }).$mount('#app');

    setInterval(async () => {
      // Se não tiver autenticado, não chamar o updateToken para não
      // redirecionar da home para a tela de login do SSO
      if (Vue.$keycloak.authenticated) {
        updateToken(
          window.location.origin + window.location.pathname,
          function () { }
        );
      }
    }, 50000);
  })

export { app }
