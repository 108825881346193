<template>
    <b-alert dismissible
        variant="danger"
        fade
        :show="showDismissibleAlert"
        class="error-alert-message"
    >
        {{ exibirMensagemErro() }}
    </b-alert>
</template>

<script>
export default {
    name: 'MessageItem',
    props: ['error', 'showDismissibleAlert'],
    methods: {
        exibirMensagemErro() {
            if(this.error && this.error.response && this.error.response.data) {
                if(this.error.response.data.message) {
                    return this.error.response.data.message
                }
                return this.error.response.data
            } else if(typeof this.error === 'string') {
                return this.error
            }
            return this.error
        }
    }
}
</script>