<template>
  <div style="width: 100%">
    <b-container>
      <b-row>
        <p><b>Atenção!</b></p>
        <p>
          &emsp;&emsp;Antes de solicitar o agendamento para a emissão da CIN, é
          importante que você saiba a diferença entre o antigo RG e a CIN, pois
          caso você ainda não a possua, a emissão será gratuita por ser a 1ª
          via. Segue uma imagem ilustrativa da CIN:
        </p>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <img
            :src="require(`@/assets/cin.png`)"
            alt="Carteira de Identidade Nacional (CIN)"
          />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <span
            ><b
              >Você já possui a sua Carteira de Identidade Nacional conforme
              modelo acima?</b
            ></span
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-radio-group
            v-model="via"
            :options="viasOpcoes"
            class="mb-3"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
          ></b-form-radio-group>
        </b-col>
      </b-row>
      <b-row class="mt-2" v-if="via == 2">
        <b-col>
          <span
            ><b
              >Você tem mais de 60 anos? (a emissão da 2ª via da CIN é gratuita
              para maiores de 60 anos)</b
            ></span
          >
        </b-col>
      </b-row>
      <b-row v-if="via == 2">
        <b-col>
          <b-form-radio-group
            v-model="maisDe60"
            :options="maisDe60Opcoes"
            class="mb-3"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
          ></b-form-radio-group>
        </b-col>
      </b-row>
      <b-row class="mt-2" v-if="via == 2">
        <b-col>
          <span
            ><b
              >Você deseja alterar algum dado? (nome, estado civil, etc.)</b
            ></span
          >
        </b-col>
      </b-row>
      <b-row v-if="via == 2">
        <b-col>
          <b-form-radio-group
            v-model="alteracaoDados"
            :options="alteracoesDadosOpcoes"
            class="mb-3"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
          ></b-form-radio-group>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <b-button
            variant="success"
            :disabled="
              !(
                via == 1 ||
                (via == 2 && maisDe60 != null && alteracaoDados != null)
              )
            "
            @click="setarVia()"
          >
            Continuar
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Via1Item",
  data() {
    return {
      via: 0,
      viasOpcoes: [
        { item: 2, name: "Sim" },
        { item: 1, name: "Não" },
      ],
      maisDe60: null,
      maisDe60Opcoes: [
        { item: true, name: "Sim" },
        { item: false, name: "Não" },
      ],
      alteracaoDados: null,
      alteracoesDadosOpcoes: [
        { item: true, name: "Sim" },
        { item: false, name: "Não" },
      ],
    };
  },

  methods: {
    setarVia() {
      this.$store.commit("passoViaDocumento");
      this.$store.commit("setVia", {
        n: this.via,
        comAlteracao: this.alteracaoDados,
        maisDe60: this.maisDe60,
        nossoNumero: null,
      });
      this.$router.push("/agendamento");
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    usuario() {
      return this.$store.getters.getUsuario;
    },
  },
};
</script>
