<template>
  <div class="text-center mb-5">
    <b-button id="Imprimir" @click="imprimir" class="noPrint">Imprimir</b-button>
    <b-button variant="outline-secondary" class="ml-5 noPrint" to="/">
      Encerrar
    </b-button>
  </div>
</template>

<script>
export default {
  name: "GenerateReport",
  methods: {
    imprimir() {
      window.print();
    },
  },
};
</script>

<style>
@media print {
  .noPrint {
    display: none;
  }
}

#relatorio {
  padding: 30px;
}

.container-report {
  max-width: 900px !important;
  font-size: larger;
}
</style>
