<template>
  <b-overlay :show="showOverlay"
             variant="transparent"
             opacity="0.9"
             blur="2px"
             rounded="sm">
    <div>
      <div class="jumbotron jumbotron-fluid p-4">
        <div class="container">
          <p class="lead">
          <ul>
            <li>A oferta de vagas é a cargo do Instituto
              de Polícia Científica (IPC).</li>
          </ul>
          </p>
        </div>
      </div>

      <!-- Formulário de agendamento -->
      <b-card>
        <template #header>
          <h4 class="mb-0">Dados do agendamento</h4>
        </template>
        <b-card-text>
          <b-form @submit.prevent="agendar">
            <!-- Local do agendamento -->
            <div class="local">
              <label for="local">Local do agendamento: </label>
              <b-form-select id="local"
                             class="mb-3"
                             v-model="agendamento.local_id"
                             @change="getDatas($event)"
                             :disabled="!(passo >= 1)"
                             required>
                <b-form-select-option v-for="local in locais"
                                      :key="local['id']"
                                      :value="local['id']">{{ local["nome"] }}
                </b-form-select-option>
              </b-form-select>
            </div>

            <!-- Data do agendamento -->
            <div class="data">
              <label for="data">Data do agendamento: </label>
              <b-calendar id="data"
                          class="my-2 mb-3 calendar"
                          v-model="agendamento.data"
                          :date-disabled-fn="dateDisabled"
                          :date-info-fn="dateClass"
                          :hide-header="true"
                          @input="getHorarios($event)"
                          :disabled="!(passo >= 2)"
                          required></b-calendar>
            </div>

            <!-- Horário do agendamento -->
            <div class="horario">
              <label for="horario">Horário do agendamento: </label>
              <b-form-select id="horario"
                             v-model="agendamento.hora"
                             :disabled="!(passo >= 3)"
                             required
                             class="mb-3">
                <b-form-select-option v-for="horario in horarios"
                                      :key="horario"
                                      :value="horario">{{ horario }}
                </b-form-select-option>
              </b-form-select>
            </div>

            <!-- button Voltar -->
            <b-button @click="voltarPasso"
                      variant="outline-primary"
                      class="float-left mt-3">Voltar
            </b-button>

            <!-- button Agendar -->
            <b-button variant="primary"
                      class="float-right mt-3"
                      type="submit">
              Agendar
            </b-button>
          </b-form>
        </b-card-text>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import axios from "axios";
import environment from "@/environment";
import ToastMixin from "@/mixins/toastMixin";

export default {
  name: "DadosAgendamento",
  mixins: [ToastMixin],
  data: function () {
    return {
      showOverlay: false,
      agendamento: {
        cpf: this.$store.getters.getUsuario.cpf,
        servico_id: environment.SERVICO_ID,
        nossoNumero: this.$store.getters.getVia.nossoNumero,
        local_id: null,
        data: null,
        hora: null,
      },
      locais: [],
      datas: [],
      horarios: [],
      retornoUsuarioServico: "",
      passo: 0,
    };
  },
  computed: {
    usuario: {
      get() {
        return this.$store.getters.getUsuario;
      },
      set(valor) {
        this.$store.commit("setUsuario", valor);
      },
    },
    getLocalSelecionado() {
      if (this.agendamento.local_id == null) {
        return "";
      }

      let a = null;
      this.locais.forEach((local) => {
        if (local['id'] == this.agendamento.local_id) {
          a = local['nome']
        }
      })
      return a != null ? a : ""
    },
  },
  methods: {
    getLocais() {
      this.showOverlay = true;
      axios
        .get(
          `${environment.BASE_URL}/servico/${this.agendamento.servico_id}/locais`,
          {isAuthenticated: true}
        )
        .then((res) => {
          this.locais = res.data.result;
          this.showOverlay = false;
        })
        .catch((error) => {
          this.makeToast(error, "Erro", "danger");
          this.showOverlay = false;
        });
      this.passo = 1;
    },
    getDatas() {
      this.$bvToast.hide()
      this.showOverlay = true;
      this.datas = [];
      this.horarios = [];
      this.agendamento.data = null;
      this.agendamento.hora = null;
      axios
        .get(
          `${environment.BASE_URL}/servico/${this.agendamento.servico_id}/local/${this.agendamento.local_id}/datas_disponiveis`,
          {isAuthenticated: true}
        )
        .then((res) => {
          this.datas = res.data.result;
          if (!this.datas.length) {
            this.makeToast(
              "Não existem vagas disponíveis neste dia para agendamento no(a) " +
              this.getLocalSelecionado + ". As vagas são oferecidas pelo Instituto de Polícia Científica (IPC).",
              "Atenção",
              "danger"
            );
          }
          this.showOverlay = false;
        })
        .catch((error) => {
          this.makeToast(error, "Erro", "danger");
          this.showOverlay = false;
        });
      this.passo = 2;
    },
    getHorarios() {
      this.showOverlay = true;
      this.horarios = [];
      this.agendamento.hora = null;
      if (
        this.agendamento.servico_id &&
        this.agendamento.local_id &&
        this.agendamento.data
      ) {
        axios
          .get(
            `${environment.BASE_URL}/servico/${this.agendamento.servico_id}/local/${this.agendamento.local_id}/` +
            `data/${this.agendamento.data}/horas_disponiveis`,
            {isAuthenticated: true}
          )
          .then((res) => {
            this.horarios = res.data.result;
            this.showOverlay = false;
          })
          .catch((error) => {
            this.makeToast(error, "Erro", "danger");
            this.showOverlay = false;
          });
        this.passo = 3;
      }
    },
    agendar() {
      this.showOverlay = true;

      if (this.agendamento.local_id == null) {
        this.makeToast("Selecione o 'Local do agendamento'.", "Erro", "danger");
        this.showOverlay = false;
        return;
      }

      if (this.agendamento.data == null || this.agendamento.data == "") {
        this.makeToast(
          "Selecione uma data disponível no calendário.",
          "Erro",
          "danger"
        );
        this.showOverlay = false;
        return;
      }

      if (this.agendamento.hora == null) {
        this.makeToast("Selecione o horário do agendamento.", "Erro", "danger");
        this.showOverlay = false;
        return;
      }

      // Adiciona/atualiza usuário
      
      axios
        .post(`${environment.BASE_URL}/usuarioServico`, this.usuario,   {isAuthenticated: true})
        .then((res) => {
          this.retornoUsuarioServico = res.data;
          // Agendamento
          axios
            .post(`${environment.BASE_URL}/agendamento`, this.agendamento,   {isAuthenticated: true})
            .then((res) => {
              this.$store.commit("setDadosAgendamento", res.data.result);
              this.$router.push("/comprovante");
            })
            .catch((error) => {
              this.makeToast(error, "Erro", "danger");
              this.showOverlay = false;
            });
        })
        .catch((error) => {
          this.makeToast(error, "Erro", "danger");
          this.showOverlay = false;
        });
    },
    onContext(ctx) {
      this.context = ctx;
    },
    dateDisabled(ymd) {
      return !this.datas.includes(ymd);
    },
    dateClass(ymd) {
      return this.datas.includes(ymd) ? "table-info" : "";
    },
    voltarPasso() {
      if (this.$store.getters.getVia.nossoNumero == null) {
        this.$store.commit("passoViaDocumento");
      } else {
        this.$router.go(-1);
      }
    },
  },
  mounted() {
    this.getLocais();
    window.scrollTo(0, 0); 
  },
};
</script>

<style>
.table.b-table.b-table-stacked>tbody>tr> :first-child {
  border-top-width: 0px !important;
}

#data__calendar-grid-help_ {
  display: none;
}

.calendar {
  @media only screen and (min-width: 768px) {
    margin-left: 50px;
  }

  @media only screen and (max-width: 767px) {
    display: flex !important;
  }
}
</style>