<template>
  <footer class="footer">
    <div>
      <a href="http://codata.pb.gov.br/">
        <img
          :src="require(`@/assets/logo_codata_branca.svg`)"
          style="margin: 15px 30px"
          height="22"
          alt="Logo da Codata no rodapé"
        />
      </a>
    </div>
    <div>
      <a href="http://paraiba.pb.gov.br/">
        <img
          :src="require(`@/assets/logo_governo_branca.png`)"
          height="40"
          alt="Logo do Governo da Paraíba no rodapé"
        />
      </a>
    </div>
    <div class="version"><span>v1.8.0</span></div>
  </footer>
</template>

<script>
export default {
  name: "MyFooter",
};
</script>

<style>
.footer {
  grid-area: footer;
  background-color: #7f8c8d;
  color: #333;

  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  text-decoration: none;
}

.version {
  font-size: 9px;
  color: white;
  margin: 40px 20px 0;
}
</style>