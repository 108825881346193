<template>
  <b-container class="containerFooter mb-5">
    <b-row class="text-center" align-h="center">
      <b-col md="3">
        <img
          id="logoCodataFooter"
          :src="require(`@/assets/rodape_codata.png`)"
          alt="Logo da Codata"
          height="30"
        />
      </b-col>
      <b-col md="3">
        <img
          id="logoGovernoFooter"
          :src="require(`@/assets/rodape_gov.png`)"
          alt="Logo do Governo do Estado da Paraíba"
          height="52"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "ReportFooter",
};
</script>

<style>
.containerFooter {
  margin-top: 80px;
}

#logoCodataFooter {
  margin: 10px 0;
}
</style>
