<template>
  <component :is="currentTemplate" />
</template>

<script>
import Default from "@/components/template/Default";
import NoTemplate from "@/components/template/NoTemplate";

export default {
  name: "App",
  components: { Default, NoTemplate },
  computed: {
    currentTemplate() {
      if (this.$route.meta.template === "no-template") {
        return NoTemplate;
      }
      return Default;
    },
  },
};
</script>

<style>
body {
  margin: 0;
}

.vue-portal-target {
  margin-top: 100px !important;
}
</style>
