<template>
  <b-overlay :show="showOverlay"
             variant="transparent"
             opacity="0.9"
             blur="2px"
             rounded="sm">
    <div class="my-3">
      <Message :error="message.error"
               :showDismissibleAlert="message.exibir" />

      <div class="jumbotron jumbotron-fluid p-4">
        <div class="container">
          <p class="lead">
            - Para emitir a segunda via da Carteira de Identidade Nacional (CIN) é
            necessário emitir e pagar o Documento de Arrecadação de Receitas
            (DAR).
          </p>
        </div>
      </div>

      <b-card class="mb-2">
        <template #header>
          <h4 class="mb-0">Documento de Arrecadação de Receitas (DAR)</h4>
        </template>
        <b-card-sub-title class="mb-2">
          <p>
            <b>Atenção!</b><br />
          </p>
          <p>
            &emsp;&emsp;Para realizar o agendamento de segunda via da Carteira de Identidade
            Nacional (CIN) é necessário, primeiramente, efetuar o pagamento do
            Documento de Arrecadação de Receitas (DAR). Após gerar o DAR, guarde
            o "Nosso Número" para que você possa consultar se já houve a compensação
            bancária, podendo levar até 72 horas para isso.
          </p>
        </b-card-sub-title>

        <!-- Emitir/consultar DAR -->
        <b-card-text v-if="!Object.keys(dar).length">
          <b-container fluid>
            <!-- Gerar DAR -->
            <b-row>
              <b-container>
                <b-row>
                  <b-button @click="gerarDar()">
                    Gerar Documento de Arrecadação de Receitas (DAR)
                  </b-button>
                </b-row>
              </b-container>
            </b-row>
          </b-container>
        </b-card-text>

        <!-- Confirmação de emissão do DAR -->
        <b-card-text v-if="exibirLinkRecuperarDAR">
          <b-container fluid>
            <!-- Gerar DAR -->
            <b-row>
              <b-container>
                <b-row>
                  <b-link style="font-size: 20px; text-decoration-line: underline"
                          to="/dar">Recuperar o último Documento de Arrecadação de Receitas
                    (DAR) em aberto
                  </b-link>
                </b-row>
                <b-row class="mt-3"
                       style="color: #6d0000">
                  Caso já tenha pago, aguarde a compensação bancária do mesmo
                  que pode ocorrer em até 72 horas.
                </b-row>
              </b-container>
            </b-row>
          </b-container>
        </b-card-text>

        <!-- button Voltar -->
        <b-button v-if="!Object.keys(dar).length"
                  @click="voltarPasso"
                  variant="outline-primary"
                  class="float-left mt-3">Voltar
        </b-button>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import axios from "axios";
import environment from "@/environment";
import Message from "@/components/template/Message";

export default {
  name: "DarItem",
  components: { Message },
  data: function () {
    return {
      showOverlay: false,
      message: { error: {}, exibir: false },
      requisicaoDar: {},
      dar: {},
      nossoNumero: "",
      respostaPagamento: "",
      contribuinte: "",
      taxasDar: {},
      exibirLinkRecuperarDAR: false,
    };
  },
  computed: {
    usuario() {
      return this.$store.getters.getUsuario;
    },
  },
  methods: {
    gerarDar() {
      this.showOverlay = true;
      this.montarRequisicaoDar();

      // É necessário enviar o endereço na requisição
      if (this.usuario.endereco === undefined) {
        this.usuario.endereco = {};
      }

      if (
        this.$store.getters.getCadastrarNoSaa === true ||
        this.$store.getters.getCadastrarNaReceita === true
      ) {
        // Adiciona/atualiza usuário
        axios
          .post(`${environment.BASE_URL}/usuarioServico`, this.usuario,   {isAuthenticated: true})
          .then((res) => {
            this.retornoUsuarioServico = res.data;
            // DAR
            this.emitirDar();
          })
          .catch((error) => {
            this.message.exibir = true;
            this.message.error = error;
            this.showOverlay = false;
            this.scrollUp();
          });
      } else {
        this.emitirDar();
      }
    },
    voltarPasso() {
      this.$store.commit("reverterAlteracaoVia");
      this.$store.commit("voltarPasso");
    },
    emitirDar() {
      axios
        .post(`${environment.BASE_URL}/dar/emitir`, this.requisicaoDar,   {isAuthenticated: true})
        .then((res) => {
          this.dar = res.data.result;
          delete this.dar.linhaDigitavel;
          this.dar.valor = this.requisicaoDar.valor;
          this.dar.vencimento = this.requisicaoDar.dataVencimentoOriginal;
          this.$store.commit("setDadosDar", this.dar);
          this.$router.push("/dar");
        })
        .catch((error) => {
          axios
            .get(
              `${environment.BASE_URL}/dar/aberto/cpf`,
              {isAuthenticated: true}
            )
            .then((res) => {
              if (res.status != 204) {
                this.exibirLinkRecuperarDAR = true;
                this.dar = res.data.result;
                this.dar.vencimento =
                  this.$tempo.formatarStringDataAdicionandoDias(
                    this.dar.dtEmissao,
                    2
                  );
                this.dar.dtEmissao = this.$tempo.formatarStringData(
                  this.dar.dtEmissao
                );
                this.$store.commit("setDadosDar", this.dar);
              }
            });
          this.message.exibir = true;
          this.message.error = error;
          this.showOverlay = false;
          this.scrollUp();
        });
    },
    montarRequisicaoDar() {
      let vencimento = new Date();
      vencimento.setDate(vencimento.getDate() + 2);

      let valor = this.$store.getters.getVia.comAlteracao
        ? this.taxasDar.valorUFR * this.taxasDar.taxaComAlteracao
        : this.taxasDar.valorUFR * this.taxasDar.taxaSemAlteracao;
      valor = parseFloat(valor.toFixed(2)).toLocaleString("pt-BR", {
        currency: "BRL",
        minimumFractionDigits: 2,
      });

      this.requisicaoDar = {
        codigoReceita: "6101",
        cpf: this.usuario.cpf,
        dataPagamento: this.$tempo.formatarData(vencimento),
        dataVencimentoOriginal: this.$tempo.formatarData(vencimento),
        informacoesComplementares: "",
        numeroDocumentoOrigem: this.usuario.cpf,
        pagamentoBancoBrasil: true,
        periodoReferencia: this.$tempo.formatarPeriodo(new Date()),
        valor: valor,
      };
    },
    scrollUp() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    axios.get(`${environment.BASE_URL}/dar/taxas`, {isAuthenticated: true}).then((res) => {
      this.taxasDar = res.data.result;
    });
    this.scrollUp();
  },
};
</script>
