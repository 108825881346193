<template>
  <div>
    <div id="relatorio">
      <ReportHeader />
      <b-container
        class="container-report"
        v-if="this.dadosConfirmados.protocolo != undefined"
      >
        <b-row class="text-center">
          <b-col><h3 class="font-weight-bold">Segurança Pública</h3></b-col>
        </b-row>
        <b-row class="text-center mb-5">
          <b-col><h4>Comprovante de Agendamento</h4></b-col>
        </b-row>
        <b-row class="mb-2" v-for="[chave, valor] in report" :key="chave">
          <b-col cols="4" md="2">
            <b>{{ chave }}</b>
          </b-col>
          <b-col cols="12" md="auto">
            {{ valor }}
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col class="text-center">
            <p>
              Seu agendamento foi realizado com sucesso! Por favor, comparecer
              ao local especificado pelo menos 30 min antes do horário agendado,
              portando toda documentação necessária para devida prestação do
              serviço.
            </p>
          </b-col>
        </b-row>
      </b-container>
      <b-container
        class="container-report text-center mt-5 text-danger h3"
        v-else
      >
        <p>Erro ao carregar o comprovante!</p>
      </b-container>
      <ReportFooter />
    </div>
    <GenerateReport />
  </div>
</template>

<script>
import ReportHeader from "@/components/utils/report/template/Header.vue";
import GenerateReport from "@/components/utils/report/template/GenerateReport.vue";
import ReportFooter from "@/components/utils/report/template/Footer.vue";

export default {
  name: "ComprovanteAgendamento",
  components: { ReportHeader, GenerateReport, ReportFooter },
  data: function () {
    return {
      dadosConfirmados: {},
      report: null,
    };
  },
  methods: {
    via() {
      if (this.$store.getters.getVia.n === 1) {
        return "Primeira via";
      } else if (this.$store.getters.getVia.n === 2) {
        if (this.$store.getters.getVia.maisDe60) {
          return "Segunda via - Maior de 60 anos";
        } else {
          return "Segunda via";
        }
      }
      return "";
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.dadosConfirmados = this.$store.getters.getDadosAgendamento;
    if (this.dadosConfirmados.protocolo != undefined) {
      this.report = new Map()
        .set("Protocolo: ", this.dadosConfirmados.protocolo)
        .set("Serviço: ", "Carteira de Identidade Nacional (CIN)")
        .set("CPF: ", this.dadosConfirmados.cidadao.cpf)
        .set("Local: ", this.dadosConfirmados.local_nome)
        .set(
          "Data: ",
          this.$tempo.formatarStringData(this.dadosConfirmados.data)
        )
        .set("Hora: ", this.dadosConfirmados.hora)
        .set("Status: ", this.dadosConfirmados.status)
        .set("Via: ", this.via());
    } else {
      this.$router.push("/");
    }
  },
};
</script>