export const somenteNumeros = {
    somenteNumeros: (evt) => {
        let key = evt.keyCode || evt.which;
        key = String.fromCharCode(key);
        const regex = /^\d+$/;
        if (!regex.test(key)) {
            evt.returnValue = false;
            if (evt.preventDefault) evt.preventDefault();
        }
    }
}

const utils = {
    somenteNumeros: (valor) => {
        return valor.replace(/\D/g, '')
    }
}

const tempo = {
    // Espera receber uma string 'yyyy-mm-dd'
    formatarStringData: (data) => {
        const parts = data.split('-');
        const mydate = new Date(parts[0], parts[1] - 1, parts[2]);
        return mydate.toLocaleDateString('pt-BR');
    },
    // Espera receber uma string 'yyyy-mm-dd'
    formatarStringDataAdicionandoDias: (data, adicionarDias) => {
        const parts = data.split('-');
        let mydate = new Date(parts[0], parts[1] - 1, parts[2]);
        mydate.setDate(mydate.getDate() + adicionarDias);
        return mydate.toLocaleDateString('pt-BR');
    },
    formatarData: (data) => {
        return data.toLocaleDateString('pt-BR', {year: 'numeric', month: 'numeric', day: 'numeric' })
    },
    formatarPeriodo: (data) => {
        return data.toLocaleDateString('pt-BR', {year: 'numeric', month: 'numeric' })
    }
}
export default { tempo, utils };
