<template>
  <div class="w-100 my-3">
    <div class="jumbotron jumbotron-fluid p-4">
      <div class="container">
        <p class="lead">
          <ul>
            <li>Informe corretamente os dados pois caso seja necessário 
              entraremos em contato</li>
            <li>Informe os dados do responsável pelo agendamento.</li>
          </ul>
        </p>
      </div>
    </div>

    <b-form @submit.prevent="proximoPasso" id="form">
      <!-- CPF -->
      <b-card class="mt-4 mb-2">
        <template #header>
          <h4 class="mb-0">CPF</h4>
        </template>
        <b-card-text class="h5">
          {{ usuario.cpf | VMask("###.###.###-##") }}
        </b-card-text>
      </b-card>
      <br>
      <!-- Endereço -->
      <b-card class="mt-4 mb-2" v-if="$store.getters.getCadastrarNaReceita">
        <template #header>
          <h4 class="mb-0">Endereço</h4>
        </template>
        <b-card-text>
          <div>
            <!-- CEP -->
            <div class="cep">
              <label for="cep">CEP: </label>
              <b-form-input
                id="cep"
                class="mb-3"
                v-model="usuario.endereco.cep"
                v-mask="'#####-###'"
                required
              ></b-form-input>
            </div>
            <!-- Logradouro -->
            <div class="logradouro">
              <label for="logradouro">Logradouro: </label>
              <b-form-input
                id="logradouro"
                class="mb-3"
                v-model="usuario.endereco.logradouro"
                maxlength="50"
                required
              ></b-form-input>
            </div>
            <!-- Número -->
            <div class="numero">
              <label for="numero">Número: </label>
              <b-form-input
                id="numero"
                class="mb-3"
                v-model="usuario.endereco.numero"
                @keypress="$func.somenteNumeros($event)"
                maxlength="5"
                required
              ></b-form-input>
            </div>
            <!-- Complemento -->
            <div class="complemento">
              <label for="complemento">Complemento: </label>
              <b-form-input
                id="complemento"
                class="mb-3"
                v-model="usuario.endereco.complemento"
                maxlength="30"
              ></b-form-input>
            </div>
          </div>
        </b-card-text>
      </b-card>

      <!-- button Voltar -->
      <b-button
        @click="voltarPasso"
        variant="outline-primary"
        class="float-left mt-3"
        >Voltar</b-button
      >

      <!-- button Próximo -->
      <b-button type="submit" variant="primary" class="float-right mt-3"
        >Próximo</b-button
      >
    </b-form>
  </div>
</template>

<script>
import Vue from "vue";
import { somenteNumeros } from "@/global.js";
import ToastMixin from "@/mixins/toastMixin";

Vue.prototype.$func = somenteNumeros;

export default {
  name: "DadosPessoais",
  mixins: [ToastMixin],
  data: function () {
    return {};
  },

  computed: {
    usuario: {
      get() {
        return this.$store.getters.getUsuario;
      },
      set(valor) {
        this.$store.commit("setUsuario", valor);
      },
    },
    via() {
      return this.$store.getters.getVia;
    },
  },

  methods: {
    proximoPasso() {
      if (
        this.$store.getters.getCadastrarNaReceita &&
        this.usuario.endereco.cep.length !== 9
      ) {
        this.makeToast("Preencha o CEP corretamente", "Erro", "danger");
      } else {
        if (
          (this.$store.getters.getCadastrarNaReceita ||
            this.$store.getters.getCadastrarNoSaa) &&
          this.via.n === 2 &&
          !this.via.maisDe60
        ) {
          this.$store.commit("passoDar");
        } else {
          this.$store.commit("incrementarPasso");
        }
      }
    },
    voltarPasso() {
      this.$store.commit("passoViaDocumento");
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
