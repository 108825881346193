<template>
  <header class="headerReport">
    <div>
      <img
        :src="require(`@/assets/pc.png`)"
        alt="Logo da Polícia Civil da Paraíba"
        class="mr-4"
      />
    </div>
    <div>
      <h2 class="text-center mt-3 mb-5">
        Agendamento de Carteira de Identidade Nacional (CIN)
      </h2>
    </div>
  </header>
</template>

<script>
export default {
  name: "ReportHeader",
};
</script>

<style>
.headerReport {
  display: flex;
  justify-content: center;
}
</style>
