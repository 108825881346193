<template>
  <div>
    <router-view></router-view>
  </div>
</template>
  
  <script>
export default {
  name: "NoTemplate",
};
</script>