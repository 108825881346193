<template>
  <div class="w-100">
    <ViaDocumento v-if="this.$store.getters.getPasso === 1" />
    <Dar v-if="this.$store.getters.getPasso === 2" />
    <DadosPessoais v-if="this.$store.getters.getPasso === 3" />
    <DadosAgendamento v-if="this.$store.getters.getPasso === 4" />
  </div>
</template>

<script>
import ViaDocumento from "./ViaDocumento";
import Dar from "./Dar";
import DadosPessoais from "./DadosPessoais";
import DadosAgendamento from "./DadosAgendamento";

export default {
  name: "AgendamentoItem",
  components: { ViaDocumento, Dar, DadosPessoais, DadosAgendamento, },
  mounted() {
    if (this.$store.getters.getVia.n === null) {
      this.$router.push("/");
    }
  }
};
</script>
