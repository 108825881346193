<template>
  <div class="info mb-4">
    <h4 id="title-content"
        class="mt-3 mb-4"
        tabindex="-1">
      <span>
        Carteira de Identidade Nacional (CIN)
      </span>
    </h4>

    <div>
      <a v-b-toggle
         href="#o-que-e"
         @click.prevent>- O que é?</a><br />
      <b-collapse id="o-que-e"
                  class="mb-4">
        <b-card>
          <p>
            Trata-se de um documento nacional que, pela primeira vez, estabelecerá um padrão de emissão
            e modelo para todos os órgãos de identificação estaduais, mitigando as fraudes de identificação
            no Brasil. A Carteira de Identidade Nacional conterá novos elementos de segurança, inclusive,
            um QR Code seguro e uma zona de leitura automatizada, com possibilidade de checagem fácil e
            confiável pelas Forças de Segurança Pública e por todos os postos de atendimentos públicos e
            privados. O número do CPF passa a ser o número do registro nacional do brasileiro, isso significa
            que o cidadão continuará com o mesmo número de identificação em qualquer estado da Federação.
          </p>
        </b-card>
      </b-collapse>
    </div>

    <div>
      <a v-b-toggle
         href="#documentos"
         @click.prevent>
        - Documentos Necessários para solicitar a CIN</a><br />
      <b-collapse id="documentos"
                  class="mb-4">
        <b-card>
          <p>
          <ul>
            <li>Certidão de Nascimento ou Casamento (original ou cópia autenticada)</li>
            <li>CPF (verificar antecipadamente sua situação cadastral frente à Receita Federal
              e se seus dados estão em conformidade com os da sua certidão -
              <a href="https://servicos.receita.fazenda.gov.br/servicos/cpf/consultasituacao/consultapublica.asp"
                 target="_blank">
                Link da Receita Federal</a>)
            </li>
            <li>Comprovante de Residência</li>
            <li>RG, se já houver</li>
          </ul>
          </p>
        </b-card>
      </b-collapse>
    </div>

    <div>
      <a v-b-toggle
         href="#opcionais"
         @click.prevent>
        - Documentos opcionais que podem ser inseridos na CIN</a><br />
      <b-collapse id="opcionais"
                  class="mb-4">
        <b-card>
          <p>
          <ul>
            <li>Grupo sanguíneo – Fator Rh</li>
          </ul>
          </p>
        </b-card>
      </b-collapse>
    </div>

    <div>
      <a v-b-toggle
         href="#menores3Anos"
         @click.prevent>- Menores de 3 anos</a><br />
      <b-collapse id="menores3Anos"
                  class="mb-4">
        <b-card>
          <p>Para crianças menores de 3 anos que possuem dificuldade na realização da
            captura de digitais por meio do módulo digital, é necessário que seja levado
            ao atendimento uma foto 3x4 recente.</p>
        </b-card>
      </b-collapse>
    </div>

    <div>
      <a v-b-toggle
         href="#rgDigital"
         @click.prevent>
        - Como obter a Carteira de Identidade Nacional (CIN) digital?</a>
      <b-collapse id="rgDigital"
                  class="mb-4">
        <b-card>
          <p>
            A CIN será emitida em duas versões, física e digital. O documento digital será
            obtido por meio do aplicativo GOV.BR, mas somente após a emissão da nova
            Carteira de Identidade Nacional (CIN) em sua versão física.
          </p>
        </b-card>
      </b-collapse>
    </div>

    <div>
      <a v-b-toggle
         href="#cidades"
         @click.prevent>- Cidades com agendamento disponível</a>
      <b-collapse id="cidades"
                  class="mb-4">
        <b-card>
          <ul>
            <li><b>João Pessoa:</b> Agendamento por meio eletrônico.</li>
            <li><b>Campina Grande:</b> Agendamento por meio eletrônico.</li>
            <li><b>Santa Rita:</b> Agendamento por meio eletrônico.</li>
            <li><b>Demais Localidades:</b> Procurar o posto de identificação do seu município.</li>
          </ul>
        </b-card>
      </b-collapse>
    </div>

    <div>
      <a v-b-toggle
         href="#outros_locais"
         @click.prevent>- Outros locais de atendimento</a>
      <b-collapse id="outros_locais"
                  class="mb-4">
        <b-card>
          <ul>
            <li>Na <b>Casa da Cidadania de Jaguaribe</b>, que atende preferencialmente idosos e PCDs,
              deverá ser feito presencialmente ou pelo número (83)3218-4473.</li>
            <li>No Programa Cidadão, no <b>Espaço Cultural</b>, o atendimento é feito por ordem de chegada.</li>
          </ul>
        </b-card>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "CollapseItem",
};
</script>

<style>
.info a {
  font-size: large;
}

.info .card {
  border: none;
}
</style>