<template>
  <b-overlay :show="showOverlay"
             variant="transparent"
             opacity="0.9"
             blur="2px"
             rounded="sm">
    <div class="my-3">
      <div class="jumbotron jumbotron-fluid p-4">
        <div class="container">
          <p class="lead">
            Formulário de agendamento para emissão de
            Carteira de Identidade Nacional (CIN).
          </p>
        </div>
      </div>
      <b-form @submit.prevent="proximoPasso"
              class="mt-3">
        <b-card class="mb-2">
          <template #header>
            <h4 class="mb-0">Dados do agendamento</h4>
          </template>
          <b-card-text>
           
            <!-- Serviço (via) -->
            <div class="via">
              <label for="via">Serviço: </label>

              <!-- 1ª via -->
              <b-form-input id="cpf"
                            class="mb-3"
                            value="Carteira de Identidade Nacional (CIN) - 1ª via"
                            disabled
                            v-if="via.n === 1"></b-form-input>

              <!-- 2ª via - Com modificação -->
              <b-form-input id="cpf"
                            class="mb-3"
                            value="Carteira de Identidade Nacional (CIN) - 2ª via - Com modificação de dados"
                            disabled
                            v-if="via.n === 2 && via.comAlteracao"></b-form-input>

              <!-- 2ª via - Sem modificação -->
              <b-form-input id="cpf"
                            class="mb-3"
                            value="Carteira de Identidade Nacional (CIN) - 2ª via - Sem modificação de dados"
                            disabled
                            v-if="via.n === 2 && !via.comAlteracao"></b-form-input>
            </div>

          </b-card-text>
        </b-card>

        <!-- Contatos -->
        <b-card class="mt-4 mb-2">
          <template #header>
            <h4 class="mb-0">Contatos</h4>
          </template>
          <b-card-text>
            <!-- Telefone -->
            <div class="telefone">
              <label for="telefone">Telefone: </label>
              <b-form-input id="telefone"
                            class="mb-3"
                            v-model="usuario.telefone"
                            v-mask="'(##)#####-####'"
                            required></b-form-input>
            </div>
            <!-- E-mail -->
            <div class="email">
              <label for="email">E-mail: </label>
              <b-form-input id="email"
                            class="mb-3"
                            type="email"
                            v-model="usuario.email"
                            maxlength="50"
                            required></b-form-input>
            </div>
          </b-card-text>
        </b-card>
        <!-- button Voltar -->
        <b-button @click="$router.go(-1)"
                  variant="outline-primary"
                  class="float-left mt-3">Voltar
        </b-button>

        <!-- button Próximo -->
        <b-button type="submit"
                  variant="primary"
                  class="float-right mt-3">
          Próximo
        </b-button>
      </b-form>
    </div>
  </b-overlay>
</template>

<script>
import Vue from "vue";
import { somenteNumeros } from "@/global.js";
import environment from "@/environment";
import axios from "axios";
import ToastMixin from "@/mixins/toastMixin";

Vue.prototype.$func = somenteNumeros;

export default {
  name: "ViaDocumento",
  mixins: [ToastMixin],
  data: function () {
    return {
      showOverlay: false,
    };
  },
  computed: {
    usuario: {
      get() {
        return this.$store.getters.getUsuario;
      },
      set(valor) {
        this.$store.commit("setUsuario", valor);
      },
    },
    via: {
      get() {
        return this.$store.getters.getVia;
      },
      set(valor) {
        this.$store.commit("setVia", valor);
      },
    },
  },
  methods: {
    consultarCidadaoSAA() {
      // Consulta usuário na base do SAA
     
      axios
        .get(
          `${environment.BASE_URL}/usuarioServico/consulta`,
          {isAuthenticated: true}
        )
        .then((res) => {
          this.usuario.cpf = this.$utils.somenteNumeros(res.data.cpf);
          this.$store.commit("setCadastrarNoSaa", !res.data.cadastradoNoSaa);
          this.$store.commit(
            "setCadastrarNaReceita",
            !res.data.cadastradoNaReceita
          );
          this.definirPasso();
        })
        .catch((error) => {
          this.makeToast(error, "Erro", "danger");
          this.showOverlay = false;
        });
    },
    proximoPasso() {
      this.showOverlay = true;
        this.consultarCidadaoSAA();
    },
    definirPasso() {
      if (this.$store.getters.getCadastrarNaReceita === true) {
        this.$store.commit("passoDadosPessoais");
      } else {
        if (
          this.via.n === 1 ||
          this.via.maisDe60
        ) {
          this.$store.commit("passoDadosAgendamento");
        } else {
          this.$store.commit("passoDar");
        }
      }
    },
    removerMascara() {
      this.usuario.cpf = this.$utils.somenteNumeros(this.usuario.cpf);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
