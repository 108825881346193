import Vue from "vue";
import { app } from '@/main.js'
import axios from 'axios'
import environment from "@/environment";

import { VueReCaptcha } from "vue-recaptcha-v3";

Vue.use(VueReCaptcha, { siteKey: environment.CHAVE_RECAPTCHA });

axios.interceptors.request.use(
  async (config) => {

    await app.$recaptchaLoaded();

    // Execute reCAPTCHA with action "login".
    const reCaptchaToken = await app.$recaptcha("login");

    config.params = { 'recaptchaToken': reCaptchaToken }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

import { updateToken } from "@/middlewares/updateToken";

axios.interceptors.request.use((config) => {
  // Verifica se a requisição necessita de autenticação
  if (config.isAuthenticated) {
    // Atualiza o token
    updateToken(window.location.toString(), function () {
      config.headers.Authorization = `Bearer ${Vue.$keycloak.token}`;
    });
  }

  return config;
});
